/* eslint-disable @typescript-eslint/no-var-requires */
import ReactDOM from 'react-dom';

import './style.scss';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

import { App } from './App';

ReactDOM.render(<App />, document.getElementById('root'));
