import { Button, Card, H3 } from '@blueprintjs/core';

const MAGIC_ID_PRVOIDER_FORM_ID = 'op.logoutForm';

import './SessionEndPage.scss';

export type SessionEndPageProps = {
  htmlForm: string;
};

export function SessionEndPage({ htmlForm }: SessionEndPageProps): JSX.Element {
  const logout = (): void => {
    const form = document.getElementById(MAGIC_ID_PRVOIDER_FORM_ID) as HTMLFormElement;

    const logoutIndicator = document.createElement('input');
    logoutIndicator.type = 'hidden';
    logoutIndicator.name = 'logout';
    logoutIndicator.value = 'yes';
    form.appendChild(logoutIndicator);

    form.submit();
  };

  const abort = (): void => {
    const form = document.getElementById(MAGIC_ID_PRVOIDER_FORM_ID) as HTMLFormElement;
    form.submit();
  };

  return (
    <Card className='logout-card'>
      <H3 className='logout-card__heading'>Logout</H3>
      <div dangerouslySetInnerHTML={{ __html: htmlForm }} />
      <div className='logout-card__buttons'>
        <Button intent='danger' onClick={logout}>Confirm</Button>
        <Button intent='none' onClick={abort}>Abort</Button>
      </div>
    </Card>
  );
}
