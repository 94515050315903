import { Button, Card, H2 } from '@blueprintjs/core';

export type ConsentPageProps = {
  interaction: any;
  uid: any;
  routerPrefix: string;
};

export function ConsentPage({ interaction: { prompt: { details }, params }, uid, routerPrefix }: ConsentPageProps): JSX.Element {
  const confirmPreviouslyGivenAuthorization = [details.missingOIDCScope, details.missingOIDCClaims, details.missingResourceScopes].filter(Boolean).length === 0;

  const missingOIDCScope = new Set(details.missingOIDCScope as any);
  missingOIDCScope.delete('openid');
  missingOIDCScope.delete('offline_access');

  const missingOIDCClaims = new Set(details.missingOIDCClaims as any);
  ['sub', 'sid', 'auth_time', 'acr', 'amr', 'iss'].forEach(Set.prototype.delete.bind(missingOIDCClaims));

  const missingResourceScopes = Object.entries(details.missingResourceScopes as any);

  return (
    <Card>
      <H2 style={{ marginTop: 0 }}>Authorize for following scopes</H2>
      <ul>
        {confirmPreviouslyGivenAuthorization && <li>the client is asking you to confirm previously given authorization</li>}
        {
          missingOIDCScope.size > 0 && (
            <>
              <li key='scopes'>scopes:</li>
              <ul>
                {
                  [...missingOIDCScope].map((scope) => (<li key={scope as any}>{scope as any}</li>))
                }
              </ul>
            </>
          )
        }
        {
          missingOIDCClaims.size > 0 && (
            <>
              <li key='claims'>claims:</li>
              <ul>
                {
                  [...missingOIDCClaims].map((claim) => (<li key={claim as any}>{claim as any}</li>))
                }
              </ul>
            </>
          )
        }

        {
          missingResourceScopes.length > 0 && (
            missingResourceScopes.map(([indicator, scopes]) => (
              <>
                <li key={indicator}>{indicator}</li>
                <ul>
                  {
                    (scopes as any).map((scope: any) => (<li key={scope}>{scope}</li>))
                  }
                </ul>
              </>
            ))
          )
        }

        {
          Array.isArray(params.scope) && Array.isArray(details.missingOIDCScope) && params.scope.includes('offline_access') && (
            <li key='offline_access'>
              the client is asking to have offline access to this authorization
              {
                (!details.missingOIDCScope) || !details.missingOIDCScope.includes('offline_access') && (
                  <>(which you{'\''}ve previously granted)</>
                )
              }
            </li>
          )
        }

      </ul>

      <form autoComplete='off' action={`${routerPrefix ?? ''}/flow/${uid}/confirm`} method='post'>
        <Button autoFocus intent='primary' type='submit' className='login login-submit'>Consent</Button>
      </form>
    </Card>
  );
}
